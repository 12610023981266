import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import * as myConstClass from '../constant.js';
import IosPulse from "react-ionicons/lib/IosPulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import routes from "../routes/index";
import './logo.scss';
// import avatar from "../assets/img/avatars/avatar.jpg";
// const avatar = `http://localhost/workzone/backend/img/profile.png`; 
// const logo = `http://localhost/workzone/backend/img/clock_new.png`;
// const zone_logo = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;

// const avatar = `https://alpha.workzoneplus.com/backend/img/profile.png`; 
// const logo = `https://alpha.workzoneplus.com/backend/img/clock_new.png`;
// const zone_logo = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

const avatar = `https://workzonepro.workzoneplus.com/backend/img/profile.png`; 
const logo = `https://workzonepro.workzoneplus.com/backend/img/clock_new.png`;
const zone_logo = `https://workzonepro.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      empData:[],
      user_id:'',
    };
  }

  toggle = index => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });
  }

  componentDidMount(){
    var admin_id= localStorage.getItem('uname'); 
  //  alert (admin_id);
  if(admin_id){
    fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    .then(response => response.json())
    .then(response =>{console.log(response);this.setState({empData: response});})
    //  alert(empData);
    .catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL+'user/getAdInfo/'+admin_id)
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
  }
  }

  render() { 
    const { sidebar } = this.props;
    var admin_id= localStorage.getItem('uname');
    const {empData} = this.state;
    let profile_img;

    if(empData.image == '' || empData.image == null){
      profile_img=  `https://workzonepro.workzoneplus.com/backend/img/profile.png`; 
    } else {
      profile_img =`http://workzonepro.workzoneplus.com/backend/uploads/banners/`+empData.image;
      // profile_img =`http://localhost/workzone/backend/uploads/banners/`+empData.image;
    }
    
    return (
      <nav
        className={classNames(
          "sidebar",
          sidebar.isOpen || "toggled",
          !sidebar.isOnRight || "sidebar-right"
        )}
      >
{/* <div class="ScrollStyle" style={{float:'left',width:'260px',overflow: 'auto',height:'650px'}}> */}
       <div className="sidebar-content">
       {/* <div style={{float:'left',width:'254px',overflow: 'auto',height:'600px'}}> */}
        <div class="ScrollStyle_sidebar" id="vertical_scroll_sidebar">
        <Link to="/dashboard" > <img class="dashboar-plus-logo top_logo" src={logo}  style={{width: '40px',height:'40px'}}/>  <img class="dashboar-plus-logo" src={zone_logo}  style={{width: '187px',height:'25px'}}/></Link>
          
        {/* <img
              src={avatar}
              className="img-fluid rounded-circle mb-2"
              alt="Linda Miller"
            /> */}

          <div className="sidebar-user">
            <img
              // src={avatar}
              src={profile_img}
              className="img-fluid rounded-circle mb-2"
              alt="Linda Miller"
            />
            <div className="font-weight-bold">{localStorage.getItem('utype')}</div>
            {/* <small>Front-end Developer</small> */}
          </div>
          <ul className="sidebar-nav">
            {routes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={this.state[index]}
                      onClick={() => this.toggle(index)}
                    >

                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul><br/>
          <p className="mb-0 copyright">
            &copy; {new Date().getFullYear()} -{" "}
            <Link to="dashboard" className="text-muted">
              Work Zone Plus
            </Link>
          </p>
        </div>
      
        </div>
        
      </nav>
      
      
    );
  }
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar
  }))(Sidebar)
);
